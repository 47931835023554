<!--
 * @Description: 企、校、社招模板详情设置模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:35:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-08 15:49:56
-->

<template>
  <div class="details-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">{{preSettings.title}}<span>（{{preSettings.description}}）</span></div>
            <span 
              class="iconfont close"
              @click="onClose"
            >
              &#xe61f;
            </span>
          </div>
          <div class="modal-body">
              <SingleChoice
                v-for="question in preSettings.questions"
                :item="question"
                @fillAnswerIntime="fillAnswerIntime"
              />
            <div class="modal-body-row flex-layout">
              <div class="modal-body-title">[此企业]指代内容：<span>*</span></div>
                <a-input
                  v-model:value="enterpriseName"
                  placeholder="请输入..." 
                />
            </div>
            <div class="modal-body-row flex-layout other">
              <div class="modal-body-title">[对标企业]名称：</div>
              <div class="input-wrap">
                <template v-for="(item, index) in otherEnterpriseNames">
                  <a-input 
                    placeholder="请输入..."
                    v-on:keyup.enter="handleAddClick"
                    v-model:value="otherEnterpriseNames[index]"
                    :id="'input_' + index"
                  />
                  <span class="iconfont close" @click="handleDeleteClick(index)">&#xe61c;</span>
                </template>
                <div class="add-btn"  @click="handleAddClick">+</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="bottom-cancel" @click="onClose">取消</div>
            <div class="bottom-pre" @click="onPrevious">上一步</div>
            <div class="bottom-finish" @click="onConfirm">创建问卷</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleChoice from './SingleChoice.vue'
import { computed, reactive, ref, toRefs, nextTick } from 'vue'
import { message } from 'ant-design-vue';

/**
 * 获取预设模板数据
 * @param { String } type 预设模板数据类型
 * @param { Object } details 上一次选择的选择题、填空题答案
 * @returns { Object } preSettings 预设模板数据
 */
const getPreSettings = (type, details) => {
  const res = require('../json/template_settings') // 模板数据
  let targetData = res.templateSettings[type] // TODO: 这里为什么能把answer填上去？
  targetData.questions.forEach(question => {
    question.answer = '' // 强制清除answer里面的数值
    if (details && details.tempalteQuestions && details.tempalteQuestions.length > 0) {
      details.tempalteQuestions.forEach(templateQuestion => {
        if (templateQuestion.questionId === question.questionId) {
          question.answer = templateQuestion.sortInTemplate // 如果details.tempalteQuestions.length大于0，证明有历史记录，那么就把答案填上去
        }
      })
    }
  })
  
  const preSettings = reactive({
    ...targetData
  })
  return { preSettings }
}

/**
 * 此企业、对标企业变量相关操作
 */
const enterpriseVariableEffect = (props) => {
  const enterpriseName = ref('') // 此企业
  const otherEnterpriseNames = ref(['']) // 对标企业
  if (props.details) {
    enterpriseName.value = props.details.enterpriseName ? props.details.enterpriseName : ''
    otherEnterpriseNames.value = props.details.otherEnterpriseNames ? props.details.otherEnterpriseNames.split('、') : ['']
  }
  // 添加对标企业
  const handleAddClick = () => {
    if (otherEnterpriseNames.value.some(item => item.trim() === '')) return
    otherEnterpriseNames.value.push('')
    nextTick(() => {
      const newInputIndex = otherEnterpriseNames.value.length - 1
      const targetDiv = document.querySelectorAll(`[id=input_${newInputIndex}]`)[0]
      targetDiv ? targetDiv.focus() : null
    })
  }
  // 删除对标企业
  const handleDeleteClick = (index) => {
    otherEnterpriseNames.value.splice(index, 1)
  }
  return {
    enterpriseName,
    otherEnterpriseNames,
    handleAddClick,
    handleDeleteClick,
  }
}

/**
 * 答案管理
 * @param { Object } preSettings          预设模板数据
 * @param { String } enterpriseName       此企业
 * @param { Array } otherEnterpriseNames  对标企业
 */
const answerEffect = (preSettings, enterpriseName, otherEnterpriseNames) => {
  // 答案填写
  const fillAnswerIntime = (questionId, choiceId) => {
    preSettings.questions.forEach(question => 
      question.questionId === questionId 
      ? (question.answer = choiceId) 
      : null);
  }
  // 校验答案
  const checkAnswer = () => {
    const warnTips = []
    // 校验单选题是否已填
    preSettings.questions.forEach((question, index) => {
      question.answer.trim() === '' ? warnTips.push(`请填写第${index + 1}题`) : null
    })
    // 校验此企业填空题是否已填
    const qLength = preSettings.questions.length
    enterpriseName.value.trim() === '' ? warnTips.push(`请填写第${qLength + 1}题`) : null
    // 校验对标企业填空题是否已填（非必填）
    // otherEnterpriseNames.value.join().trim() === '' ? warnTips.push(`请填写第${qLength + 2}题`) : null
    return warnTips.join('\n')
  }
  // 获取答案
  const getAnswer = () => {
    const answer = {
      enterpriseName: enterpriseName.value,
      otherEnterpriseNames: otherEnterpriseNames.value.filter(item => item.trim() !== '').join('、'),
      tempalteQuestions: preSettings.questions.map(question => ({'questionId': question.questionId, 'sortInTemplate': question.answer}))
    }
    return answer
  }
  return {
    fillAnswerIntime,
    checkAnswer,
    getAnswer
  }
}

/**
 * 模态窗按钮相关操作
 */
const buttonEffect = (ctx, getAnswer, checkAnswer) => {
  const onConfirm = () => {
    const warnTips = checkAnswer()
    if (warnTips !== '') {
      message.error(warnTips)
      return
    }
    const answer = getAnswer()
    ctx.emit('modal-on-confirm', answer)
  }
  const onClose = () => {
    ctx.emit('modal-on-close')
  }
  const onPrevious = () => {
    const answer = getAnswer()
    ctx.emit('modal-on-pre', answer)
  }
  return {
    onConfirm,
    onClose,
    onPrevious,
  }
}


export default {
  name: 'DetailsModal',
  components: {
    SingleChoice,
  },
  props: {
    type: String,
    details: Object
  },
  emits: [
    'modal-on-confirm',
    'modal-on-close',
  ],
  setup (props, ctx) {
    const { preSettings } = getPreSettings(props.type, props.details)
    const { enterpriseName, otherEnterpriseNames, handleAddClick, handleDeleteClick } = enterpriseVariableEffect(props)
    const { fillAnswerIntime, getAnswer, checkAnswer } = answerEffect(preSettings, enterpriseName, otherEnterpriseNames)
    const { onConfirm, onClose, onPrevious } = buttonEffect(ctx, getAnswer, checkAnswer)

    return {
      preSettings,
      enterpriseName,
      otherEnterpriseNames,
      handleAddClick,
      handleDeleteClick,
      onConfirm,
      onClose,
      onPrevious,
      fillAnswerIntime
    }
  }
}
</script>

<style lang="less">
.details-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    .modal {
      box-sizing: border-box;
      width: 900px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      left: 50%; 
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      -webkit-transform: translate(-50%, -50%);
      pointer-events: none;
      .modal-content {
        position: relative;
        border: 16px solid #eff2f5;
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;
        pointer-events: auto;
        .modal-header {
          position: relative;
          padding: 16px 24px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-radius: 0.125rem 0.125rem 0 0;
          box-sizing: border-box;
          .modal-header-title {
            width: auto;
            font-size: 20px;
            font-weight: 500;
            word-wrap: break-word;
            display: inline-block;
          }
          .close {
            position: absolute;
            top: 14px;
            right: 16px;
            font-size: 20px;
            .pointer()
          }
        }
        .modal-body {
          padding: 20px 24px 40px 24px;
          min-height: 300px;
          max-height: 600px;
          overflow: auto;
          .modal-body-row {
            margin-bottom: 24px;
            .modal-body-title {
              margin-bottom: 20px;
              font-size: 18px;
              span {
                color: red
              }
            }
            .ant-radio-group {
              display: flex;
              .ant-radio-wrapper {
                margin-right: 60px;
                display: flex;
                .ant-radio {
                  align-self: center;
                  .ant-radio-inner {
                    &::after {
                      width: 9px;
                      height: 9px;
                      left: 50%; 
                      top: 50%;
                      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
                      -webkit-transform: translate(-50%, -50%);
                    }
                  }
                }
                span {
                  &:nth-of-type(2) {
                    flex: 1;
                    word-break: break-all;
                    overflow: hidden;
                    white-space: pre-wrap;
                    line-height: 1.5rem;
                    padding-right: 1.875rem;
                  }
                }
              }
            }
          }
          .flex-layout {
            display: flex;
            align-items: center;
            .modal-body-title {
              flex: 0 0 auto;
              margin-bottom: 0;
            }
            input {
              flex: 0 1 400px;
              margin-left: 20px;
              border: none;
              border-bottom: 1px solid #d7d7d7;
            }
          }
          .other {
            .modal-body-title {
              align-self: flex-start;
              padding-top: 4px;
            }
            .input-wrap {
              flex: 1 1 auto;
              display: flex;
              flex-wrap: wrap;
              margin-left: 10px;
              input {
                flex: 0 1 220px;
                margin-left: 0;
                margin-bottom: 20px;
              }
              .close {
                padding-top: 4px;
                margin-left: 10px;
                margin-right: 20px;
                cursor: pointer;
              }
              .add-btn {
                margin-left: 30px;
                width: 60px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                border: 1px dashed transparent;
                background: linear-gradient(white,white) padding-box,
                repeating-linear-gradient(-45deg,#ccc 0, #ccc 0.25em,white 0,white 0.55em);
                user-select: none;
                .pointer();
              }
            }
          }
        }
        .modal-footer {
          display: flex;
          margin: 34px 0;
          justify-content: center;
          div {
            flex: none;
            padding: 6px 20px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 4px;
            background-color: #f59a23;
            cursor: pointer;
          }
          .bottom-cancel, .bottom-pre {
            color: #c8c8c8;
            background-color: #fff;
            border: 1px solid #c8c8c8;
            margin-right: 40px;
          }
          .bottom-finish {

          }
        }
      }
    }
  } 
}
</style>>