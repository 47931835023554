/*
 * @Description: 企、社、校模板数据处理
 * @version: 
 * @Author: PSG
 * @Date: 2021-07-01 14:40:10
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-08 15:59:26
 */
import { createUniqueId } from '@/utils/random'
const ENTERPRISE_NAME = '此企业'
const OTHER_ENTERPRISE_NAME = '对标企业'
const useTemplateConstructEffect = () => {
  // 关键字替换
  const keywordReplacement = (str, oldKeyword, newKeyword) => {
    const reg = new RegExp(`\\[${oldKeyword}\\]`, 'g')
    return str.replace(reg, newKeyword)
  }
  // 题目数据关键字全局替换
  const globalKeyWordReplacement = (questionnaire, preSettings) => {
    let str = JSON.stringify(questionnaire)
    const enterpriseName = preSettings.enterpriseName // 此企业
    const otherEnterpriseNames = preSettings.otherEnterpriseNames // 对标企业
    str = keywordReplacement(str, ENTERPRISE_NAME, enterpriseName)
    if (otherEnterpriseNames !== '') {
      str = keywordReplacement(str, OTHER_ENTERPRISE_NAME, otherEnterpriseNames)
    }
    return JSON.parse(str)
  }
  // 根据已选问题模板，过滤无用模板题目
  const filterNeedlessQuestion = (questionnaire, tempalteQuestions) => {  
    let newQuestions = null // 过滤后的问题题目
    const id2Sort = {} // 将题目id、题目模板序号sortInTemplate 变成key-value
    tempalteQuestions.forEach(question => {
      id2Sort[question.questionId] = question.sortInTemplate
    })
    newQuestions = questionnaire.questions.filter(question => {
      const curQuestionId = question.questionId
      if (id2Sort.hasOwnProperty(curQuestionId)) {
        if (question.sortInTemplate == id2Sort[curQuestionId]) {
          return true
        }
        return false
      }
      return true
    })
    questionnaire.questions = newQuestions
  }
  // 单选选项数据批量构建
  const choiceDataConstructor = (indicators) => {
    const newChoiceList = []
    indicators.forEach(indicator => {
      newChoiceList.push({
        'choiceId': createUniqueId(8), //选项id
        'text': indicator, //选项内容
        'description': '', //选项描述
        'ifAllowFillInBlanks': false, //是否能填空
        'ifRequired': false, //是否必填
        'pic': '', //图片
        "ifMutuallyExclusive": null, // 是否互斥
        "showLogic": null, // 选项显示逻辑
        "value": null, 
        "ifIgnore": null
      })
    })
    return newChoiceList
  }
  // 题目数据批量构建
  const questionDataConstructor = (templateQuestion, targetQuestionId, choiceData) => {
    const indicator = choiceData.text
    const choiceId = choiceData.choiceId
    const reg = /xx指标/g
    const str = JSON.stringify(templateQuestion).replace(reg, indicator)
    const newQuestion = JSON.parse(str)
    newQuestion.questionId = createUniqueId(10)
    newQuestion.showLogic = `${targetQuestionId};${choiceId}`
    return newQuestion
  }
  // 根据指标新增选项
  const insertChoiceByIndicators = (questionnaire, indicators) => {
    const questions = questionnaire.questions
    questions.forEach(question => {
      // choiceIndicatorsRange 字段有内容，证明该题目需要根据指标来新增选项
      if (question.choiceIndicatorsRange && question.choiceIndicatorsRange.length > 0) {
        question.choices = []
        question.choiceIndicatorsRange.forEach(range => {
          question.choices.push(choiceDataConstructor(indicators[range]))
        })
        question.choices = question.choices.flat()
      }
    })
  }
  // 根据指标新增题目
  const insertQuestionByRef = (questionnaire) => {
    // 思路：如果题目有newQuestionRef字段且不为空，证明该题目是模板题目 
    //    需要根据newQuestionRef字段对应的questionId，找到对应的题目A，根据A题目的选项内容新增题目
    const questions = questionnaire.questions
    // 1、要拿到需要根据字表批量新增的题目（获取题目原型数据，题目所在下标，构建以key-value的map）
    //  e.g const index2question = {index: {questionTemplate, newQuestions}}
    //      index => 题目所在的下标位置；
    //      questionTemplate => 原题数据（以该题目为模板新增题目）
    //      newQuestions => 新增的题目列表（将这些题目插入进index位置，把原来index位置的题目删除）
    const index2question = {}
    questions.forEach((question, index) => {
      if (question.newQuestionRef) {
        index2question[index + ''] = {
          questionTemplate: question,
          newQuestions: []
        }
      }
    })
    // 2、根据index2question，填充里面的newQuestions字段（根据指标新增题目）
    for (let index in index2question) {
      let targetQuestionId = index2question[index].questionTemplate.newQuestionRef
      let targetQuestion = questions.filter(question => question.questionId === targetQuestionId)[0]
      targetQuestion.choices.forEach(choice => {
        let curTemplateQuestion = index2question[index].questionTemplate
        let newQuestion = questionDataConstructor(curTemplateQuestion, targetQuestionId, choice)
        index2question[index].newQuestions.push(newQuestion)
      })
    }
    // 3、根据index2question，剔除index位置的原题目，然后把批量新增的题目newQuestions加入到index位置
    for (let i = questions.length - 1; i >= 0; i--) {
      if (index2question[i]) {
        questions.splice(i, 1, index2question[i].newQuestions)
      }
    }
    questionnaire.questions = questions.flat() // 数组降纬
  }
  // 根据【此企业】、【对标企业】、构建矩阵行标题
  const insertRowTitleByEnterpriseName =(questionnaire, enterpriseName, otherEnterpriseNames) => {
    let rowTitleList = []
    rowTitleList.push(enterpriseName)
    otherEnterpriseNames && rowTitleList.push(otherEnterpriseNames.split('、'))
    rowTitleList = rowTitleList.flat()
    const targetDatas = questionnaire.questions.filter(question => question.rowItemsRange && question.rowItemsRange.length > 0)
    targetDatas.forEach(question => {
      const rowTitleChoiceList = []
      rowTitleList.forEach(rowTitle => {
        rowTitleChoiceList.push({
          "text": rowTitle,
          "description": ""
        })
      })
      if (otherEnterpriseNames === '') {
        rowTitleChoiceList.push({
          "text": '[对标企业]',
          "description": ""
        })
      }
      question.rowItems = rowTitleChoiceList
    })
  }
  // 根据模板预算信息构建问卷结构
  const constructQuestWithTemplate = (questionnaire, preSettings) => {
    const { indicators, enterpriseName,  otherEnterpriseNames, tempalteQuestions } = preSettings
    filterNeedlessQuestion(questionnaire, tempalteQuestions)
    insertChoiceByIndicators(questionnaire, indicators)
    insertQuestionByRef(questionnaire)
    insertRowTitleByEnterpriseName(questionnaire, enterpriseName, otherEnterpriseNames)
    return globalKeyWordReplacement(questionnaire, preSettings)
  }
  return {
    constructQuestWithTemplate,
  }
}
export default useTemplateConstructEffect