<!--
 * @Description: 问卷模板
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-17 15:06:18
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-06 11:05:53
-->
<template>
  <Header 
    :isShowLogo="true" 
    :isShowHelp="true"
  />
  <div class="template">
    <div class="wrap">
      <div class="top">新建问卷类型</div>
      <div class="content">
        <div class="inner">
          <div class="panel" v-for="template in templateData">
            <div class="panel-title">{{template.type}}</div>
            <div class="panel-content">
              <div 
                class="box" 
                v-for="item in template.list"
                @click="handleNewTemplateClick(item)"
              >
                <span class="box-title">{{item.title}}</span>
                <span class="box-desc" v-if="item.description && item.id <= 3">({{item.description}})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <NameModal
    v-if="nameModalVisible"
    @modal-on-close="nameModalVisible = false"
    @modal-on-confirm="handleNameCallback"
  />
  <IndicatorsModal
    v-if="indicatorsModalVisible"
    :indicators="alreadySelectedIndicators"
    :templateInfo="curClickTemplate"
    @modal-on-close="handleCancelCallback"
    @modal-on-confirm="handleIndicatorsCallback"
  />
  <DetailsModal
    v-if="detailsModalVisible"
    :type="curClickTemplate.template"
    :details="alreadySelectedDetails"
    @modal-on-close="handleCancelCallback"
    @modal-on-pre="handlePreviousCallback"
    @modal-on-confirm="hanleDetailsCallback"
  />
</template>

<script>
import Header from '@/components/common/Header'
import NameModal from './components/NameModal'
import IndicatorsModal from './components/IndicatorsModal'
import DetailsModal from './components/DetailsModal'

import useTemplateConstructEffect from './effects/templateConstructor'

import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { saveQuestionnaire } from '@/api/questionnaire'
import { deepCopy } from '@/utils/deepCopy'

// 模板类型
const BASE_TEMPLATE = [{
  type: '',
  list: [{
    id: 0,
    title: '空白问卷',
    template: '',
    description: ''
  }, {
    id: 1,
    title: '员工诊断',
    description: '入职',
    template: 'employee_in',
    src: './json/employee_in.json'
  }, {
    id: 2,
    title: '员工诊断',
    description: '在职',
    template: 'employee_on',
    src: './json/employee_on.json'
  }, {
    id: 3,
    title: '员工诊断',
    description: '离职',
    template: 'employee_out',
    src: './json/employee_out.json'
  }]
}, {
  type: '指标少',
  list: [{
    id: 4,
    type: 'few',
    title: '企业价值调研',
    description: '指标少',
    template: 'enterprise_few',
    src: './json/enterprise_few.json'
  }, {
    id: 5,
    type: 'few',
    title: '校招认知调研',
    description: '指标少',
    template: 'campus_few',
    src: './json/campus_few.json'
  }, {
    id: 6,
    type: 'few',
    title: '社招认知调研',
    description: '指标少',
    template: 'society_few',
    src: './json/society_few.json'
  }]
}, {
  type: '指标多',
  list: [{
    id: 7,
    type: 'multiple',
    title: '企业价值调研',
    description: '指标多',
    template: 'enterprise_multiple',
    src: './json/enterprise_multiple.json'
  }, {
    id: 8,
    type: 'multiple',
    title: '校招认知调研',
    description: '指标多',
    template: 'campus_multiple',
    src: './json/campus_multiple.json'
  }, {
    id: 9,
    type: 'multiple',
    title: '社招认知调研',
    description: '指标多',
    template: 'society_multiple',
    src: './json/society_multiple.json'
  }]
}]

// 新建模板相关操作
const newTemplateEffect = () => {
  const router = useRouter()
  const curClickTemplate = ref(null) // 当前需要新增的模板基本信息
  const nameModalVisible = ref(false) // 是否显示企业名称模态窗
  const indicatorsModalVisible = ref(false) // 是否显示指模态窗
  const detailsModalVisible = ref(false) // 是否显示模板详情设置模态窗
  // 新建模板点击
  const handleNewTemplateClick = (item) => {
    curClickTemplate.value = item
    // 空白问卷
    if (item.id === 0) {
      router.push('/edit/questionEdit/new')
    // 员工诊断
    } else if (item.id <= 3) {
      nameModalVisible.value = true
    // 企、校、社模板
    } else {
      indicatorsModalVisible.value = true
    }
  }

  // 保存
  const save = async (questionnaire) => {
    delete questionnaire.questionnaireProtoId
    const res = await saveQuestionnaire(questionnaire)
    if (res.data?.code === '00000') {
      router.push(`/edit/questionEdit/${res.data.data}`) // 创建成功后跳到编辑
    }
  }
  return {
    curClickTemplate,
    nameModalVisible,
    indicatorsModalVisible,
    detailsModalVisible,
    handleNewTemplateClick,
    save,
  }
}

// 员工诊断模板相关操作
const employeeDiagnosisEffect = (nameModalVisible, curClickTemplate, save) => {
  // 员工诊断模板数据处理
  const employeeDataConstructor = (data, companyName) => {
    let str = JSON.stringify(data)
    str = str.replace(/\[此企业\]/g, companyName)
    return JSON.parse(str)
  }

  // 员工诊断弹出框确认回调
  const handleNameCallback = (companyName) => {
    nameModalVisible.value = false
    let res = require(`${curClickTemplate.value.src}`)
    let resCopy = deepCopy(res) // TODO: 不知道为什么修改了res的数据，导致原json文件的数据也被改变，因此复制一份，不修改原数据
    resCopy = employeeDataConstructor(resCopy, companyName)
    save(resCopy.data)
  }

  return {
    handleNameCallback
  }
}

// 企、校、社认知调研模板相关操作
const cognitionSurveyEffect = (indicatorsModalVisible, detailsModalVisible, curClickTemplate, save) => {
  const alreadySelectedIndicators = ref({}) // 已选择的指标
  const alreadySelectedDetails = ref({}) // 已选择的模板详情
  const { constructQuestWithTemplate } = useTemplateConstructEffect()
  // 选择指标后模态窗回调
  const handleIndicatorsCallback = (indicators) => {
    alreadySelectedIndicators.value = indicators
    indicatorsModalVisible.value = false
    detailsModalVisible.value = true
  }
  // 选择模板设置详情后模态窗回调
  const hanleDetailsCallback = (alreadySelectedDetails) => {
    const preSettings = {...alreadySelectedDetails}
    preSettings.indicators = alreadySelectedIndicators.value
    
    const res = require(`${curClickTemplate.value.src}`)
    const data = deepCopy(res.data) // TODO: 不知道为什么修改了res的数据，导致原json文件的数据也被改变，因此复制一份，不修改原数据
    const questionnaire = constructQuestWithTemplate(data, preSettings)
    save(questionnaire)
  
    detailsModalVisible.value = false
    // 清空已选指标
    alreadySelectedIndicators.value = {}
  }
  // 选择模板设置上一步按钮回调
  const handlePreviousCallback = (details) => {
    alreadySelectedDetails.value = details
    detailsModalVisible.value = false
    indicatorsModalVisible.value = true
  }
  // 取消操作回调
  const handleCancelCallback = () => {
    indicatorsModalVisible.value = false
    detailsModalVisible.value = false
    alreadySelectedIndicators.value = {}

  }
  return {
    alreadySelectedIndicators,
    alreadySelectedDetails,
    handleIndicatorsCallback,
    hanleDetailsCallback,
    handlePreviousCallback,
    handleCancelCallback
  }
}


export default {
  components: {
    Header,
    NameModal,
    IndicatorsModal,
    DetailsModal,
  },
  setup () {
    const templateData = BASE_TEMPLATE
    const { curClickTemplate, nameModalVisible, indicatorsModalVisible, detailsModalVisible, handleNewTemplateClick, save } = newTemplateEffect()
    const { handleNameCallback } = employeeDiagnosisEffect(nameModalVisible, curClickTemplate, save)
    const { alreadySelectedIndicators, alreadySelectedDetails, handleIndicatorsCallback,  handlePreviousCallback, hanleDetailsCallback, handleCancelCallback} = cognitionSurveyEffect(indicatorsModalVisible, detailsModalVisible, curClickTemplate, save)
    return {
      templateData,
      curClickTemplate,
      nameModalVisible,
      indicatorsModalVisible,
      detailsModalVisible,
      handleNewTemplateClick,
      handleNameCallback,
      alreadySelectedIndicators,
      alreadySelectedDetails,
      handleIndicatorsCallback,
      handlePreviousCallback,
      hanleDetailsCallback,
      handleCancelCallback
    }
  }
}
</script>

<style lang="less" scoped>
.template {
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  background-color: @backgroundColor;
  .wrap {
    width: 1300px;
    margin: 20px auto;
    background: #fff;
    border-radius: 10px;
    .top {
      padding-top: 30px;
      margin: 0 50px;
      height: 80px;
      font-size: 24px;
      font-weight: bold;
    }
    .content {
      .inner {
        width: 90%;
        margin: 0 auto;
        padding-bottom: 60px;
        .panel {
          margin-top: 50px;
          &:first-child {
            margin-top: 10px;
          }
          .panel-title {
            font-size: 22px;
            font-weight: bold;
            margin-left: 60px;
            margin-bottom: -10px;
          }
          .panel-content {
            display: flex;
            flex-wrap: wrap;
            .box {
              flex: none;
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 160px;
              height: 200px;
              margin-top: 30px;
              margin-left: 60px;
              border: 1px solid #c0c0c0;
              background-color: #f9fcff;
              &:hover {
                background-color: #fff;
                cursor: pointer;
                border: 1px solid #00A0E9;
              }
              .box-title {
                font-size: 20px;
                font-weight: bold;
              }
              .box-desc {
                font-size: 16px;
                margin-top: 10px;
              }
            } 
          }
        }
      }
    }
  }
}
</style>