/*
 * @Description: 模板api
 * @version: 
 * @Author: PSG
 * @Date: 2021-03-16 16:36:48
 * @LastEditors: PSG
 * @LastEditTime: 2021-06-23 16:16:39
 */
import service from '@/utils/request.js'

/**
 * 获取指标
 * @param {string} typeId 选项分类id
 */
 export function getOptionList(typeId) {
  return service.request({
    url: `/config/options/detail?optionsLibId=${typeId}`,
    method: 'get'
  })
}