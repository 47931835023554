<!--
 * @Description: 指标模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-23 15:30:34
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-02 15:10:48
-->
<template>
  <div class="indicators-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">{{templateInfo.title}}<span>（{{templateInfo.description}}）</span></div>
            <span 
              class="iconfont close"
              @click="onClose"
            >
              &#xe61f;
            </span>
          </div>
          <div class="modal-body">
            <div class="modal-body-desc">请选择需要使用的雇主价值指标：<span>*</span></div>
            <div class="modal-body-type">
              <div class="type-name">组织层级</div>
              <a-checkbox-group v-model:value="orgCheckedList">
                <a-checkbox 
                  v-for="(indicator) in orgIndicators"
                  :value='indicator'
                >{{indicator}}</a-checkbox>
                
              </a-checkbox-group>
            </div>
            <div class="modal-body-type">
              <div class="type-name">团队层级</div>
              <a-checkbox-group v-model:value="teamCheckedList">
                <a-checkbox 
                  v-for="(indicator) in teamIndicators"
                  :value='indicator'
                >{{indicator}}</a-checkbox>
                
              </a-checkbox-group>
            </div>
            <div class="modal-body-type">
              <div class="type-name">个人层级</div>
              <a-checkbox-group v-model:value="selfCheckedList">
                <a-checkbox 
                  v-for="(indicator) in selfIndicators"
                  :value='indicator'
                >{{indicator}}</a-checkbox>
                
              </a-checkbox-group>
            </div>
          </div>
          <div class="modal-footer">
            <div class="bottom-cancel" @click="onClose">取消</div>
            <div class="bottom-finish" @click="onConfirm">下一步</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue'
import { message } from 'ant-design-vue';
import { getOptionList } from '@/api/template'
/**
 * 常量定义
 */
const ORG_INDICATORS_ID = 'c493eca8-c0b8-4aca-8e44-624f466768b7'
const TEAM_INDICATORS_ID = 'abf234c9-a4b6-4d6d-ba28-38f36842626b'
const SELF_INDICATORS_ID = '4cd30a64-c2e9-40b6-95f0-e0c95f6c35af'

/**
 * 获取指标数据
 */
const getIndicatorsEffect = () => {
  const data = reactive({
    orgIndicators: [],
    teamIndicators: [],
    selfIndicators: []
  })
  const getIndicators = async (...arg) => {
    const result1 = await getOptionList(arg[0])
    const result2 = await getOptionList(arg[1])
    const result3 = await getOptionList(arg[2])
    data.orgIndicators = result1.data.data.options
    data.teamIndicators = result2.data.data.options
    data.selfIndicators = result3.data.data.options
  }
  getIndicators(ORG_INDICATORS_ID, TEAM_INDICATORS_ID, SELF_INDICATORS_ID)
  return toRefs(data)
}

/**
 * 已选后的相关变量
 */
const checkVariableEffect = (props) => {
  const obj = toRefs(props.indicators)
  const orgCheckedList = obj && obj['org']? obj['org'] : ref([]) // 组织指标已选
  const teamCheckedList = obj && obj['team']? obj['team'] : ref([]) // 团队指标已选
  const selfCheckedList = obj && obj['self']? obj['self'] : ref([]) // 个人指标已选
  const allCheckedList = computed(() => ({
    org: orgCheckedList.value,
    team: teamCheckedList.value,
    self: selfCheckedList.value
  })) // 已选汇总
  return {
    orgCheckedList,
    teamCheckedList,
    selfCheckedList,
    allCheckedList
  }
}

/**
 * 模态窗按钮相关操作
 */
const buttonEffect = (props, ctx, allCheckedList) => {
  const onConfirm = () => {
    // 指标多的问卷新增，必须组织、团队、个人层级的指标都要至少勾选一个
    if (props.templateInfo.type === 'multiple') {
      if (Object.values(allCheckedList.value).some(checkedList => checkedList.length === 0)) {
        message.warning('每一层级的指标至少勾选一个', 1.5)
        return
      }
    // 指标少的问卷新增，一共至少勾选一个指标（不分层级）
    } else {
      if (Object.values(allCheckedList.value).flat().length === 0) {
        message.warning('请您勾选指标', 1.5)
        return
      } 
    }
    ctx.emit('modal-on-confirm', allCheckedList.value)
  }
  const onClose = () => {
    ctx.emit('modal-on-close')
  }
  return {
    onConfirm,
    onClose,
  }
}

export default {
  name: 'IndicatorsModal',
  props: {
    templateInfo: Object,
    indicators: Object,
  },
  emits: [
    'modal-on-confirm',
    'modal-on-close',
  ],
  setup (props, ctx) {
    const { orgCheckedList, teamCheckedList, selfCheckedList, allCheckedList } = checkVariableEffect(props)
    const {orgIndicators, teamIndicators, selfIndicators} = getIndicatorsEffect()
    const { onConfirm,  onClose } = buttonEffect(props, ctx, allCheckedList)

    return {
      orgCheckedList,
      teamCheckedList,
      selfCheckedList,
      orgIndicators,
      teamIndicators,
      selfIndicators,
      allCheckedList,
      onConfirm,
      onClose,
    }
  }
}
</script>

<style lang="less">
.indicators-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    .modal {
      box-sizing: border-box;
      width: 900px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      left: 50%; 
      top: 50%;
      transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
      -webkit-transform: translate(-50%, -50%);
      pointer-events: none;
      .modal-content {
        position: relative;
        border: 16px solid #eff2f5;
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;
        pointer-events: auto;
        .modal-header {
          position: relative;
          padding: 16px 24px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-radius: 0.125rem 0.125rem 0 0;
          box-sizing: border-box;
          .modal-header-title {
            width: auto;
            font-size: 20px;
            font-weight: 500;
            word-wrap: break-word;
            display: inline-block;
          }
          .close {
            position: absolute;
            top: 14px;
            right: 16px;
            font-size: 20px;
            .pointer()
          }
        }
        .modal-body {
          padding: 0 24px 40px 24px;
          min-height: 300px;
          max-height: 600px;
          overflow: auto;
          .modal-body-desc {
            margin-bottom: 16px;
            font-size: 18px;
            span {
              color: red
            }
          }
          .modal-body-type {
            .type-name {
              margin-bottom: 18px;
              font-weight: bold;
            }
          }
          .ant-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            .ant-checkbox-wrapper {
              margin-left: 0;
              margin-bottom: 20px;
              flex: 0 0 33.33%;
              display: flex;
              .ant-checkbox {
                margin-top: 4px;
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .modal-footer {
          display: flex;
          margin: 34px 0;
          justify-content: center;
          div {
            flex: none;
            padding: 6px 20px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 4px;
            background-color: #f59a23;
            cursor: pointer;
          }
          .bottom-cancel {
            color: #c8c8c8;
            background-color: #fff;
            border: 1px solid #c8c8c8;
            margin-right: 40px;
          }
          .bottom-finish {

          }
        }
      }
    }
  } 
}
</style>>