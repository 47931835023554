<!--
 * @Description: 模板设置，单选组件
 * @version: 
 * @Author: PSG
 * @Date: 2021-06-28 17:50:36
 * @LastEditors: PSG
 * @LastEditTime: 2021-07-06 15:49:27
 -->
<template>
  <div class="details-single">
    <div class="title">{{item.title}}<span>*</span></div>
    <a-radio-group 
      :class="{'layout-colunm': item.layout === 1}"
      v-model:value="radioValue"
      @change="sendAnswerIntime(item.questionId, radioValue)"
    >
      <a-radio
        v-for="choice in item.choices"
        :value="choice.choiceId"
      >{{choice.text}}</a-radio>
    </a-radio-group>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
export default {
  name: 'SingleChoice',
  props: [
    'item'
  ],
  emits: [
    'fillAnswerIntime',
  ],
  setup (props, ctx) {
    const radioValue = ref()
    if (props.item && props.item.answer) {
      radioValue.value = props.item.answer
    }
    const sendAnswerIntime = (questionId, choiceId) => {
      ctx.emit('fillAnswerIntime', questionId, choiceId)
    }
    return {
      radioValue,
      sendAnswerIntime
    }
  }
}
</script>

<style lang="less">
  .details-single {
    margin-bottom: 24px;
    .title {
      margin-bottom: 20px;
      font-size: 18px;
      span {
        color: red
      }
    }
    .layout-colunm {
      flex-direction: column;
      .ant-radio-wrapper {
        margin-right: 0!important;
        margin-bottom: 22px;
      }
    }
    .ant-radio-group {
      display: flex;
      .ant-radio-wrapper {
        display: flex;
        &:last-child {
          margin-bottom: 0;
        }
        .ant-radio {
          align-self: center;
          .ant-radio-inner {
            &::after {
              width: 9px;
              height: 9px;
              left: 50%; 
              top: 50%;
              transform: translate(-50%, -50%); /* 50%为自身尺寸的一半 */
              -webkit-transform: translate(-50%, -50%);
            }
          }
        }
        span {
          &:nth-of-type(2) {
            flex: 1;
            word-break: break-all;
            overflow: hidden;
            white-space: pre-wrap;
            line-height: 1.5rem;
            padding-right: 1.875rem;
          }
        }
      }
    }
  }
  .flex-layout {
    display: flex;
    align-items: center;
    .title {
      flex: 0 0 auto;
      margin-bottom: 0;
    }
    input {
      flex: 0 1 400px;
      margin-left: 20px;
      border: none;
      border-bottom: 1px solid #d7d7d7;
    }
  }
</style>