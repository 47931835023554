<!--
 * @Description: 员工诊断新增模态窗
 * @version: 
 * @Author: PSG
 * @Date: 2021-04-20 16:35:45
 * @LastEditors: PSG
 * @LastEditTime: 2021-06-24 10:16:11
-->
<template>
  <div class="name-modal">
    <div class="modal-mask"></div>
    <div class="modal-wrap">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-header-title">员工诊断</div>
            <span 
              class="iconfont close"
              @click="onClose"
            >
              &#xe61f;
            </span>
          </div>
          <div class="modal-body">
            <div class="desc">调研的企业名称：</div>
            <span class="star">*</span>
            <a-input 
              v-model:value="text" 
              v-on:keyup.enter="onConfirm"
              allow-clear
            />
          </div>
          <div class="modal-footer">
            <div class="bottom-cancel" @click="onClose">取消</div>
            <div class="bottom-finish" @click="onConfirm">创建问卷</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { message } from 'ant-design-vue';
export default {
  name: 'NameModal',
  props: {
    title: String,
  },
  emits: [
    'modal-on-confirm',
    'modal-on-close',
  ],
  setup (props, ctx) {
    const text = ref('')
    
    const clearText = () => {
      text.value = ''
    }
    
    const onConfirm = () => {
      if (!text.value.trim()) {
        message.warning('请输入调研的企业名称', 1.5)
        return
      } 
      ctx.emit('modal-on-confirm', text.value)
      clearText()
    }

    const onClose = () => {
      ctx.emit('modal-on-close')
      clearText()
    }

    return {
      text,
      onConfirm,
      onClose,
    }
  }
}
</script>

<style lang="less">
.name-modal {
  .modal-mask {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);
  }
  .modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow: auto;
    outline: 0;
    .modal {
      box-sizing: border-box;
      width: 900px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      line-height: 1.5715;
      list-style: none;
      position: relative;
      top: 100px;
      margin: 0 auto;
      padding-bottom: 24px;
      pointer-events: none;
      .modal-content {
        position: relative;
        border: 16px solid #eff2f5;
        border-radius: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: #fff;
        pointer-events: auto;
        .modal-header {
          position: relative;
          padding: 16px 24px;
          color: rgba(0, 0, 0, 0.85);
          background: #fff;
          border-radius: 0.125rem 0.125rem 0 0;
          box-sizing: border-box;
          .modal-header-title {
            width: 90px;
            font-size: 20px;
            font-weight: 500;
            word-wrap: break-word;
            display: inline-block;
          }
          .close {
            position: absolute;
            top: 14px;
            right: 16px;
            font-size: 20px;
            .pointer()
          }
        }
        .modal-body {
          display: flex;
          justify-content: center;
          padding: 40px 40px;
          .desc {
            flex: 0 0 auto;
            font-size: 16px;
            align-self: center;
          }
          .star {
            color: red;
            margin-left: 2px;
            margin-right: 20px;
            align-self: center;
          }
          .ant-input-affix-wrapper {
            flex: 0 0 260px;
            border: none;
            input {
              border-bottom: 1px solid #d7d7d7;
              width: 260px;
              font-size: 16px;
            }
          }
        }
        .modal-footer {
          display: flex;
          margin: 34px 0;
          justify-content: center;
          div {
            flex: none;
            padding: 6px 20px;
            font-weight: 400;
            font-size: 14px;
            text-align: center;
            color: #FFFFFF;
            border-radius: 4px;
            background-color: #f59a23;
            cursor: pointer;
          }
          .bottom-cancel {
            color: #c8c8c8;
            background-color: #fff;
            border: 1px solid #c8c8c8;
            margin-right: 40px;
          }
          .bottom-finish {

          }
        }
      }
    }
  } 
}
</style>>